import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Offer} from '../model/offer';
import {catchError, delay, map, share} from 'rxjs/operators';
import { of, tap } from 'rxjs';
import { DictionaryValue } from './DIctionary'


const TEST_OFFER = {
  id: 22,
  title: "HDFC securities",
  brand: {
    imageUrl: "https://cdn.cashli.in/uploads/brand_icon/0eeb74d0-3b97-4358-ad16-eff0a1580a89"
  },
  trackingUrl: "https://ad.admitad.com/g/tr8zsetsn0d7cfb512bd1af15d686c/?subid={AGENT_ID}&subid4={CLICK_ID}",
  trafficBackUrl: '',
  fullDescription: "<p>💻 Fully digital process for opening an account, which takes only 10 minutes.&nbsp;</p>\n\n<p>💲 The company provides access to a range of investment products, including equities, derivatives, mutual funds, and currencies.</p>\n\n<p>🌎 The campaign also offers world-class research services to assist in making informed investment decisions.&nbsp;</p>\n\n<p>👍 The reliable and trustworthy brand with a robust track record of accomplishments.</p>\n",
  howToSell: {
    requiredDocuments: "<p>1. Aadhar Card</p>\n\n<p>2. Signature on Blank Paper</p>\n\n<p>3. PAN Card</p>\n\n<p>4. Bank account Details/Statement (Screenshot or Photo)</p>\n\n<p>5. Selfie</p>\n",
    fees: "<p>Minimum average balance is Rs.10,000 for Savings Account PRO</p><p>Minimum average balance is Rs.25,000 for Savings Account PRO Plus</p>",
    targetAudience: "<p>Age: 18 or above</p><p>Indian resident.</p>"
  },
}

export interface PreLandingData {
  title: string;
  brandUrl: string;
  features: string;
  feesAndCharges: string;
  requiredDocuments: string;
}

export interface PreLandingSubmitRequest {
  offerId?: number;
  trafficSourceId?: number;
  phoneNumber: number;
  name?: string;
  pan?: string;
  email?: string;
  pinCode?: string;
  extUrlParameter?: string | null;
}

export interface PreLandingResponse {
  redirectUrl: string
}

export interface LandingBlock {
  pos: number
  title: string
  color: string
  imageUrl: string
}

export interface CashbackLanding {
  id: number | null
  url: string
  imageUrl: string
  title: string
  thirdPartyInfo: string
  disclaimer: string
  blocks: LandingBlock[]
}

export interface CashbackSettings {
  id: number | null
  termsAndCondition: string
  cashbackType: DictionaryValue // cashback type text
  status: 'ACTIVE' | 'INACTIVE'
  landing: CashbackLanding
  restrictions: CashbackRestrictions
}

export interface CashbackRestrictions {
  hideCashliLogo: boolean
  cashliLogoMustShown: number[] // trafficSourceId
  allowForUsers: number[] // trafficSourceId
  prohibitedForUsers: number[] // trafficSourceId
  goalsBan: number[] // goalId
  productsBan: number[] // productId
}

export interface CashbackSettingsResponse {
  landingSettings: {
    goals: CashbackSettings[]
    offer: CashbackSettings
    products: CashbackSettings[]
  }
  offer: PreLandingData
}


@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(private httpClient: HttpClient) { }

  getById(id: number, cashbackId: number | undefined = undefined) {
    const url = `${environment.backendEndpoint}/public/landing?offerId=${id}` +
      (cashbackId ? `&cashbackId=${cashbackId}` : '')
    debugger
    return this.httpClient.get<PreLandingData>(url).pipe(delay(1));
  }

  getCashbackPageSettings(offerId: number, cashbackUrlId: number) {
    const url = `${environment.backendEndpoint}/public/cashbackLanding?offerId=${offerId}&cashbackUrlId=${cashbackUrlId}`
    return this.httpClient.get<CashbackSettingsResponse>(url).pipe(delay(1))
  }

  submitRequest(request: PreLandingSubmitRequest) {
    const url = `${environment.backendEndpoint}/public/landing`
    return this.httpClient.post<PreLandingResponse>(url, request).pipe(delay(1))
  }
}
