import {Pipe, PipeTransform} from '@angular/core';
import {isObservable, Observable, of} from 'rxjs';
import {catchError, delay, filter, map, startWith} from 'rxjs/operators';

@Pipe({
  name: 'loading'
})
export class LoadingPipe implements PipeTransform {

  transform<T>(obs: Observable<T>, pause = 1): Observable<{ loading?: boolean; error?: any; value?: T }> {
    return isObservable(obs)
      ? obs.pipe(
        delay(pause),
        filter(v => v !== undefined),
        map((value: any) => ({loading: false, value})),
        startWith({loading: true}),
        catchError(error => {
          if (error?.status === 404) {
            error = 'Backend communication error. Resource not found'
          }
          return of({loading: false, error}).pipe(delay(pause))
        })
      )
      : obs;
  }

}
