import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { OfferService, PreLandingSubmitRequest } from '../../../api/offer.service'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent {
  isLoading = false;
  constructor (private bottomSheetRef: MatBottomSheetRef<DocumentListComponent>,
               @Inject(MAT_BOTTOM_SHEET_DATA) public data: {text: string},
               public offerService: OfferService,
               private snack: MatSnackBar) {
  }
  close() {
    this.bottomSheetRef.dismiss()
  }
}
