import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent {
  interval

  constructor(public dialogRef: MatDialogRef<LoadingDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {redirectUrl: string, delay: number}) {
    this.interval = setInterval(() => this.data.delay === 0 ? this.redirect() : this.data.delay--, 1000);
  }

  redirect() {
    clearInterval(this.interval)
    location.href = this.data.redirectUrl;
  }
}
