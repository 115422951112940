import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingPipe} from './pipes/loading.pipe';
import {SafePipe} from './pipes/safe.pipe';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { ConfirmBottomComponent } from './components/confirm-bottom/confirm-bottom.component';
import { MatIconModule } from '@angular/material/icon';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { ProductCardComponent } from './components/product-card/product-card.component'
import { CdkAccordionModule } from '@angular/cdk/accordion'
import { MatCardModule } from '@angular/material/card'
import { MatExpansionModule } from '@angular/material/expansion';
import { DictionaryValuesPipe } from './pipes/dictionary-values.pipe'


@NgModule({
  declarations: [
    LoadingPipe,
    SafePipe,
    LoadingDialogComponent,
    ConfirmBottomComponent,
    DocumentListComponent,
    ProductCardComponent,
    DictionaryValuesPipe,
  ],
  exports: [
    LoadingPipe,
    SafePipe,
    ProductCardComponent,
    DictionaryValuesPipe,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    CdkAccordionModule,
    MatCardModule,
  ]
})
export class SharedModule {
}
