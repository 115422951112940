import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {CommonModule} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {NgxMaskModule} from 'ngx-mask';
import { InterceptorService } from './interceptor.service'
import { AppRoutingModule } from './app-routing.module';
import { RootPrelanderComponent } from './root-prelander/root-prelander.component';
import { StaticPrelanderComponent } from './static-prelander/static-prelander.component'
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { MatChipsModule } from '@angular/material/chips'
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CashbackPrelanderComponent } from './cashback-prelander/cashback-prelander.component';

@NgModule({
  declarations: [AppComponent, RootPrelanderComponent, StaticPrelanderComponent, CashbackPrelanderComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatCardModule,
    MatTabsModule,
    MatInputModule,
    MatCheckboxModule,
    SharedModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDialogModule,
    MatBottomSheetModule,
    NgxMaskModule.forRoot(),
    MatChipsModule,
    CdkAccordionModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
