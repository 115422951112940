import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { PRELANDER_VARIANTS, PrelanderVariant } from './static-prelander.data'
import { Observable, of, tap } from 'rxjs'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { ConfirmBottomComponent } from '../shared/components/confirm-bottom/confirm-bottom.component'
import { PRELANDER_LAYOUT_CASHLI_LOGO } from '../model/prelandaer'
import { DocumentListComponent } from '../shared/components/document-list/document-list.component'

@Component({
  selector: 'app-static-prelander',
  templateUrl: './static-prelander.component.html',
  styleUrls: ['./static-prelander.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StaticPrelanderComponent {
  offerVariant: PrelanderVariant | undefined;
  offer$!: Observable<PrelanderVariant | undefined>;
  form!: FormGroup;
  isLoading = false;
  extUrlParameter: string | null = null;
  isShowCashliLogo: boolean = true;

  public readonly ptmLink = 'https://p.paytm.me/xCTH?pid=qr_code&c=UPI_PSA&referral_unique_link=vDU1BCu&custom_campaign=invite_campaign&is_retargeting=true&af_dp=paytmmp://homepage_primary';

  constructor (private route: ActivatedRoute,
               private fb: FormBuilder,
               private bottomSheet: MatBottomSheet) {
  }

  ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search)
    const offerId = Number(urlParams.get('offerId'))
    const trafficSourceId = Number(urlParams.get('tsId'))
    this.extUrlParameter = urlParams.get('ext')
    this.isShowCashliLogo = Number(urlParams.get('cl')) !== PRELANDER_LAYOUT_CASHLI_LOGO.HIDE

    this.offerVariant = PRELANDER_VARIANTS.find(v => v.code === this.route.snapshot.paramMap.get('variant'));
    this.offer$ = of(this.offerVariant).pipe()
    this.form = this.fb.group({
      offerId: [offerId],
      trafficSourceId: [trafficSourceId],
      phoneNumber: [undefined, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      extUrlParams: [this.extUrlParameter],
    });
  }

  redirect(url: string) {
    //location.href = url
    window.open(url, "_blank");
  }

  openBottomSheet(): void {
    this.bottomSheet.open(ConfirmBottomComponent, {data: this.form.getRawValue()});
  }

  docDetails(text: string): void {
    this.bottomSheet.open(DocumentListComponent, {data: { text }});
  }

  getRequirements(item: { group: string; requirements: { name: string; value: string; }[]; }[]): { group: string; requirements: { name: string; value: string; }[]; }[] {
    return item
  }
}
