import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { CashbackLanding, CashbackSettings, CashbackSettingsResponse, OfferService } from '../api/offer.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Observable, tap } from 'rxjs'
import { ConfirmBottomComponent } from '../shared/components/confirm-bottom/confirm-bottom.component'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-cashback-prelander',
  templateUrl: './cashback-prelander.component.html',
  styleUrls: ['./cashback-prelander.component.scss']
})
export class CashbackPrelanderComponent {
  offerId!: number
  trafficSourceId!: number
  cashbackUrlId!: number
  extUrlParameter: string | null = null
  cashback$!: Observable<CashbackSettingsResponse>
  isLogoVisible: boolean = false
  settings!: CashbackSettings
  landingSettings!: CashbackLanding

  form!: FormGroup

  public readonly ptmLink = 'https://p.paytm.me/xCTH?pid=qr_code&c=UPI_PSA&referral_unique_link=vDU1BCu&custom_campaign=invite_campaign&is_retargeting=true&af_dp=paytmmp://homepage_primary';


  constructor (public dialog: MatDialog,
               public offerService: OfferService,
               private fb: FormBuilder,
               private ar: ActivatedRoute,
               private bottomSheet: MatBottomSheet,
               private snack: MatSnackBar,) {
  }

  ngOnInit (): void {
    this.offerId = Number(this.ar.snapshot.queryParams['offerId'])
    this.trafficSourceId = Number(this.ar.snapshot.queryParams['tsId'])
    this.cashbackUrlId = Number(this.ar.snapshot.queryParams['cashbackUrlId'])
    this.extUrlParameter = this.ar.snapshot.queryParams['ext']

    if (this.offerId && this.cashbackUrlId) {
      this.cashback$ = this.offerService.getCashbackPageSettings(this.offerId, this.cashbackUrlId).pipe(
        tap(v => {
          const gSettings = v.landingSettings.goals[0]
          const pSettings = v.landingSettings.products[0]
          const oSettings = v.landingSettings.offer

          this.settings = oSettings || gSettings || pSettings // todo change order
          this.landingSettings =
            (v.landingSettings.goals && v.landingSettings.goals && v.landingSettings.goals?.length == 1 && v.landingSettings.goals[0]?.landing) ||
            (v.landingSettings.products && v.landingSettings.products && v.landingSettings.products?.length == 1 && v.landingSettings.products[0]?.landing) ||
            oSettings?.landing

          this.isLogoVisible = v.landingSettings.offer.restrictions.hideCashliLogo ||
            v.landingSettings.offer.restrictions.prohibitedForUsers.some(u => u == this.trafficSourceId)
        }),
      )

      this.form = this.fb.group({
        offerId: [this.offerId],
        trafficSourceId: [this.trafficSourceId],
        phoneNumber: [undefined, [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10)
        ]],
        extUrlParams: [this.extUrlParameter],
      });
    }
  }

  redirect (url: string) {
    //location.href = url
    window.open(url, "_blank");
  }

  openBottomSheet (): void {
    this.bottomSheet.open(ConfirmBottomComponent, { data: this.form.getRawValue() });
  }
}
