import { PrelanderVariant } from '../static-prelander.data'

export const AXIS_CC_Prelander: PrelanderVariant = {
  code: 'AXIS_CC',
  settings: {
    main: {
      fontClass: 'mat-headline-2',
      title: 'Apply for<br><span style="color: #528DFF">Axis credit card</span>',
      img: 'assets/AXIS_CC_main.png',
      action: 'and get'
    },
    paytm: {
      title: 'REWARDS',
      value: 1000
    },
    products: {
      title: 'Credit cards',
      items: [
        {
          img: 'assets/AXIS_CC_product_01.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'Neo Card',
          tags: ['Shopping'],
          documents:
            '<p>The following documents are required:</p>\n' +
            '<ul>\n' +
            '  <li>PAN card photocopy or Form 60</li>\n' +
            '  <li>Colour photograph</li>\n' +
            '  <li>Latest payslip/Form 16/IT return copy as proof of income</li>\n' +
            '</ul>\n' +
            '<p>Residence proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Ration Card</li>\n' +
            '  <li>Electricity bill</li>\n' +
            '  <li>Landline telephone bill</li>\n' +
            '</ul>\n' +
            '<p>Identity proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving license</li>\n' +
            '  <li>PAN card</li>\n' +
            '  <li>Aadhaar card</li>\n' +
            '</ul>',
          fees: [
            {
              name: 'Annual fee',
              value: '250 ₹',
            },
            {
              name: 'Joining fee',
              value: '250 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text:
                '<ul class="ul">\n' +
                '  <li>100% cashback up to Rs. 300 on utility bill payment</li>\n' +
                '  <li>Upto 10% off on Blinkit and Myntra</li>\n' +
                '  <li>1 EDGE REWARD point on every Rs. 200 spent</li>\n' +
                '  <li>You get 15% off on dining at partner restaurants</li>\n' +
                '  <li>Enjoy 40% off on ordering food worth Rs. 200 and above from Zomato twice a month</li>\n' +
                '  <li>You get 10% off on booking movie tickets on BookMyShow</li>\n' +
                '</ul>'
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '42 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '42 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AXIS_CC_product_02.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'Ace Card',
          tags: ['Shopping'],
          documents: '' +
            '<p>The following documents are required:</p>\n' +
            '<ul>\n' +
            '  <li>PAN card photocopy</li>\n' +
            '  <li>Colour photograph</li>\n' +
            '  <li>Latest payslip/Bank statement/Form 16/IT return copy as proof of income</li>\n' +
            '</ul>\n' +
            '<p>Residence proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving License</li>\n' +
            '  <li>Voter ID Card</li>\n' +
            '  <li>Aadhaar card</li>\n' +
            '</ul>\n' +
            '<p>Identity proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving license</li>\n' +
            '  <li>Voter ID Card</li>\n' +
            '  <li>Aadhaar card</li>\n' +
            '  <li>PAN card</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '499 ₹',
            },
            {
              name: 'Joining fee',
              value: '499 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text:
                '<ul>\n' +
                '  <li>You get a cashback of 5% on utility bill payments</li>\n' +
                '  <li>Accelerated (4%) cashback on Swiggy and Zomato and Ola</li>\n' +
                '  <li>4 complimentary airport lounge access every year to selected domestic airports</li>\n' +
                '</ul>'
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '42 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '42 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AXIS_CC_product_03.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'Flipkart super Elite',
          tags: ['Shopping'],
          documents: '' +
            '<p>The following documents are required:</p>\n' +
            '<ul>\n' +
            '  <li>PAN card photocopy or Form 60</li>\n' +
            '  <li>Colour photograph</li>\n' +
            '  <li>Latest payslip/Form 16/IT return copy as proof of income</li>\n' +
            '</ul>\n' +
            '<p>Residence proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Ration Card</li>\n' +
            '  <li>Electricity bill</li>\n' +
            '  <li>Landline telephone bill</li>\n' +
            '</ul>\n' +
            '<p>Identity proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving license</li>\n' +
            '  <li>PAN card</li>\n' +
            '  <li>Aadhaar card</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '500 ₹',
            },
            {
              name: 'Joining fee',
              value: '500 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text:
                '<ul>\n' +
                '  <li>500 bonus SuperCoins as an activation benefit</li>\n' +
                '  <li>2-month complimentary YouTube Premium trial membership</li>\n' +
                '  <li>3-month complimentary Gaana plus trial membership</li>\n' +
                '  <li>1-year complimentary membership to Lenskart</li>\n' +
                '  <li>Flipkart Plus members get 12 SuperCoins on every spend of Rs. 100 on their purchases on Flipkart</li>\n' +
                '</ul>'
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '42 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '42 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AXIS_CC_product_04.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'Pride signature',
          tags: ['Shopping', 'Travel'],
          documents: '' +
            '<p>The following documents are required:</p>\n' +
            '<ul>\n' +
            '  <li>PAN card photocopy or Form 60</li>\n' +
            '  <li>Colour photograph</li>\n' +
            '  <li>Latest payslip/Form 16/IT return copy as proof of income</li>\n' +
            '</ul>\n' +
            '<p>Residence proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Ration Card</li>\n' +
            '  <li>Electricity bill</li>\n' +
            '  <li>Landline telephone bill</li>\n' +
            '</ul>\n' +
            '<p>Identity proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving license</li>\n' +
            '  <li>PAN card</li>\n' +
            '  <li>Aadhaar card</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '500 ₹',
            },
            {
              name: 'Joining fee',
              value: 'Nil ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '' +
                '<ul>\n' +
                '  <li>1% fuel surcharge is waived off at all filling stations across India</li>\n' +
                '  <li>Minimum discount of 15% on dining bills</li>\n' +
                '  <li>You earn 8 EDGE REWARD Points on every Rs. 200</li>\n' +
                '  <li>You get 2 complimentary domestic lounge access every calendar quarter at select airports across India</li>\n' +
                '</ul>'
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Defense personnels',
                  items: [
                    {
                      name: 'Credit score',
                      value: '650+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AXIS_CC_product_05.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'Flipkart Axis',
          tags: ['Shoping'],
          documents: '<p>The following documents are required:</p>\n' +
            '<ul>\n' +
            '  <li>PAN card photocopy or Form 60</li>\n' +
            '  <li>Colour photograph</li>\n' +
            '  <li>Latest payslip / Form 16 / IT return copy as proof of income</li>\n' +
            '</ul>\n' +
            '<p>Residence proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Ration Card</li>\n' +
            '  <li>Electricity bill</li>\n' +
            '  <li>Landline telephone bill</li>\n' +
            '</ul>\n' +
            '<p>Identity proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving License</li>\n' +
            '  <li>PAN Card</li>\n' +
            '  <li>Aadhaar card</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '500 ₹',
            },
            {
              name: 'Joining fee',
              value: '500 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '' +
                '<ul>\n' +
                '  <li>5% cashback on Flipkart</li>\n' +
                '  <li>4% cashback on preferred partners</li>\n' +
                '  <li>Accelerated cashback (4%) on PVR cinemas</li>\n' +
                '  <li>Introductory benefits worth Rs. 1,100 on card issuance</li>\n' +
                '  <li>4 complimentary airport lounge access within India</li>\n' +
                '</ul>'
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '15 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '30 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AXIS_CC_product_06.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'Axis Myzone',
          tags: ['Movies'],
          documents: '<p>Keep your documents ready</p>\n' +
            '<p>The following documents are required:</p>\n' +
            '<ul>\n' +
            '  <li>PAN card photocopy or Form 60</li>\n' +
            '  <li>Colour photographs</li>\n' +
            '  <li>Latest payslip / Form 16 / IT return copy as income proof</li>\n' +
            '</ul>\n' +
            '<p>Residential proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Ration Card</li>\n' +
            '  <li>Electricity bill</li>\n' +
            '  <li>Landline telephone bill</li>\n' +
            '</ul>\n' +
            '<p>Identity proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving license</li>\n' +
            '  <li>PAN card</li>\n' +
            '  <li>Aadhaar card</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '500 ₹',
            },
            {
              name: 'Joining fee',
              value: '500 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '' +
                '<ul>\n' +
                '  <li>Complimentary annual premium membership of SonyLiv worth Rs. 999</li>\n' +
                '  <li>Get Rs. 120 off on Swiggy orders of minimum Rs. 500</li>\n' +
                '  <li>You earn 4 EDGE Points per Rs. 200</li>\n' +
                '  <li>Buy One Get One offer on movie tickets</li>\n' +
                '</ul>'
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '15 000+'
                    },
                    {
                      name: 'Age',
                      value: '18-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '30 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AXIS_CC_product_07.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'Indian Oil',
          tags: ['Movies', 'Fuel'],
          documents: '<p>The following documents are required:</p>\n' +
            '<ul>\n' +
            '  <li>PAN card photocopy or Form 60</li>\n' +
            '  <li>Colour photograph</li>\n' +
            '  <li>Latest payslip/Form 16/IT return copy as proof of income</li>\n' +
            '</ul>\n' +
            '<p>Residence proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Ration Card</li>\n' +
            '  <li>Electricity bill</li>\n' +
            '  <li>Landline telephone bill</li>\n' +
            '</ul>\n' +
            '<p>Identity proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving license</li>\n' +
            '  <li>PAN card</li>\n' +
            '  <li>Aadhaar card</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '500 ₹',
            },
            {
              name: 'Joining fee',
              value: '500 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '<ul>\n' +
                '  <li>100% cashback (up to Rs. 250) on fuel transactions</li>\n' +
                '  <li>Up to 15% off up to Rs. 500 on dining bills</li>\n' +
                '  <li>20 EDGE Points per Rs. 100 spent on fuel purchases at all IOCL stations</li>\n' +
                '  <li>Instant 10% discount on movie ticket bookings on BookMyShow</li>\n' +
                '</ul>',
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '15 000+'
                    },
                    {
                      name: 'Age',
                      value: '18-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '30 000+'
                    },
                    {
                      name: 'Age',
                      value: '18-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AXIS_CC_product_08.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'Samsung Signature',
          tags: ['Shopping'],
          documents:
            '<p>The following documents are required:</p>\n' +
            '<ul>\n' +
            '  <li>PAN card photocopy</li>\n' +
            '  <li>Colour photographs</li>\n' +
            '  <li>Latest payslip/Bank statement/Form 16/IT return copy as proof of income</li>\n' +
            '</ul>\n' +
            '<p>Residence proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving License</li>\n' +
            '  <li>Voter ID Card</li>\n' +
            '  <li>Aadhaar card</li>\n' +
            '</ul>\n' +
            '<p>Identity proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving License</li>\n' +
            '  <li>Voter ID Card</li>\n' +
            '  <li>Aadhaar card</li>\n' +
            '  <li>PAN card</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '500 ₹',
            },
            {
              name: 'Joining fee',
              value: '500 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '<ul>\n' +
                '  <li>Get a 10% cashback on all their purchases from the Samsung brand</li>\n' +
                '  <li>Earn 10 Edge Rewards on every spend of Rs. 100 on partnered brands</li>\n' +
                '  <li>4 complimentary domestic airport lounge access</li>\n' +
                '</ul>',
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '50 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '50 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AXIS_CC_product_09.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'Spicejet Voyage',
          tags: ['Travel'],
          documents:
            '<p>The following documents are required:</p>\n' +
            '<ul>\n' +
            '  <li>PAN card photocopy or Form 60</li>\n' +
            '  <li>Colour photograph</li>\n' +
            '  <li>Latest payslip/Form 16/IT return copy as proof of income</li>\n' +
            '</ul>\n' +
            '<p>Residence proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Ration Card</li>\n' +
            '  <li>Electricity bill</li>\n' +
            '  <li>Landline telephone bill</li>\n' +
            '</ul>\n' +
            '<p>Identity proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving license</li>\n' +
            '  <li>PAN card</li>\n' +
            '  <li>Aadhaar card</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '750 ₹',
            },
            {
              name: 'Joining fee',
              value: '750 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '' +
                '<ul>\n' +
                '  <li>SpiceJet flight voucher of Rs. 1,500 on completion of 2 transactions within 30 days</li>\n' +
                '  <li>Up to 20% on bills at dining partners</li>\n' +
                '  <li>Upto 18 Spicejet Points on each spend</li>\n' +
                '  <li>4 Complimentary domestic airport Lounge Access with Spicejet Silver membership</li>\n' +
                '</ul>',
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '50 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '50 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AXIS_CC_product_10.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'Privilege Card',
          tags: ['Shopping'],
          documents:
            '<p>The following documents are required:</p>\n' +
            '<ul>\n' +
            '  <li>PAN card photocopy or Form 60</li>\n' +
            '  <li>Latest payslip / Form 16 / IT return copy as income proof</li>\n' +
            '</ul>\n' +
            '<p>Residential proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving License</li>\n' +
            '  <li>Ration Card</li>\n' +
            '  <li>Electricity bill</li>\n' +
            '  <li>Landline telephone bill</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '1,500 ₹',
            },
            {
              name: 'Joining fee',
              value: '1,500 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '' +
                '<ul>\n' +
                '  <li>You get 10,000 EDGE REWARD POINTS as a welcome benefit on your first transaction within 30 days of issuance.</li>\n' +
                '  <li>Up to 20% off on Axis dining partners</li>\n' +
                '  <li>Earn 10 EDGE Points per Rs. 200 you spend</li>\n' +
                '  <li>2 Complimentary domestic airport Lounge Access</li>\n' +
                '</ul>',
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '50 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '50 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AXIS_CC_product_11.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'SpiceJet Voyage black',
          tags: ['Travel'],
          documents:
            '<p>The following documents are required:</p>\n' +
            '<ul>\n' +
            '  <li>PAN card photocopy</li>\n' +
            '  <li>Colour photograph</li>\n' +
            '  <li>Latest payslip/Form 16/IT return copy as proof of income</li>\n' +
            '</ul>\n' +
            '<p>Residence proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Ration Card</li>\n' +
            '  <li>Electricity bill</li>\n' +
            '  <li>Landline telephone bill</li>\n' +
            '</ul>\n' +
            '<p>Identity proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving license</li>\n' +
            '  <li>PAN card</li>\n' +
            '  <li>Aadhaar card</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '2,000 ₹',
            },
            {
              name: 'Joining fee',
              value: '2,000 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '' +
                '<ul>\n' +
                '  <li>Complimentary SpiceClub Gold membership</li>\n' +
                '  <li>SpiceJet flight voucher worth Rs. 4,000</li>\n' +
                '  <li>Upto 28 Spicejet Points on each spend</li>\n' +
                '  <li>8 Complimentary domestic airport Lounge Access</li>\n' +
                '</ul>',
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '50 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '50 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AXIS_CC_product_12.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'Select Card',
          tags: ['Travel', 'Shopping'],
          documents:
            '<p>The following documents are required:</p>\n' +
            '<ul>\n' +
            '  <li>PAN card photocopy or Form 60</li>\n' +
            '  <li>Latest payslip/Form 16/IT return copy as proof of income</li>\n' +
            '</ul>\n' +
            '<p>Residence proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving License</li>\n' +
            '  <li>Ration card</li>\n' +
            '  <li>Electricity bill</li>\n' +
            '  <li>Landline telephone bill</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '3,000 ₹',
            },
            {
              name: 'Joining fee',
              value: '3,000 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '' +
                '<ul>\n' +
                '  <li>You get 10,000 EDGE REWARD POINTS as a welcome benefit on your first transaction within 30 days of issuance.</li>\n' +
                '  <li>Up to 40% off on Swiggy</li>\n' +
                '  <li>Earn 20 EDGE Points per Rs. 200 you spend</li>\n' +
                '  <li>Buy 1 movie ticket on BookMyShow and get up to Rs. 300 off on the second ticket</li>\n' +
                '  <li>2 Complimentary domestic airport Lounge Access and 6 international airport lounge access</li>\n' +
                '</ul>',
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '50 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '50 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AXIS_CC_product_13.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'Vistara Signature',
          tags: ['Travel', 'Dining'],
          documents:
            '<p>The following documents are required:</p>\n' +
            '<ul>\n' +
            '  <li>PAN card photocopy or Form 60</li>\n' +
            '  <li>Proof of income: latest payslip / Form 16 / IT return</li>\n' +
            '</ul>\n' +
            '<p>Residence proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving License</li>\n' +
            '  <li>Ration Card</li>\n' +
            '  <li>Electricity bill</li>\n' +
            '  <li>Landline telephone bill</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '3,000 ₹',
            },
            {
              name: 'Joining fee',
              value: '3,000 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '' +
                '<ul>\n' +
                '  <li>1 complimentary Premium Economy Class air ticket on signing up for the card</li>\n' +
                '  <li>Complimentary Club Vistara Silver membership</li>\n' +
                '  <li>For every Rs. 200 spent with this credit card, you will earn 4 CV Points</li>\n' +
                '  <li>You get a discount of 25% up to Rs. 800 on dining bills</li>\n' +
                '  <li>Get 2 complimentary domestic airport lounge</li>\n' +
                '</ul>\n',
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '50 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '50 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AXIS_CC_product_14.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'Atlas card',
          tags: ['Travel'],
          documents:
            '<p>The below documents are required for application:</p>\n' +
            '<ul>\n' +
            '  <li>PAN card photocopy or Form 60</li>\n' +
            '  <li>Latest payslip/ Form 16/IT return copy as proof of income</li>\n' +
            '  <li>Residence proof (Passport/Driving license/ Electricity Bill/ Landline telephone bill)</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '5,000 ₹',
            },
            {
              name: 'Joining fee',
              value: '5,000 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '' +
                '<ul>\n' +
                '  <li>You get 5,000 Edge Miles as a welcome gift</li>\n' +
                '  <li>Avail Discounts of up to 25% up to Rs. 800</li>\n' +
                '  <li>Get a 1% waiver on the surcharge</li>\n' +
                '  <li>Unlimited domestic and international airport lounge access</li>\n' +
                '</ul>\n',
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '75 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '120 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AXIS_CC_product_15.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'Vistara Infinite',
          tags: ['Travel'],
          documents:
            '<p>The below documents are required for application:</p>\n' +
            '<ul>\n' +
            '  <li>Photocopy of PAN card or of Form 60</li>\n' +
            '  <li>Income proof: latest payslip/Form 16/IT return</li>\n' +
            '</ul>\n' +
            '<p>Residence proof (any one of the following):</p>\n' +
            '<ul>\n' +
            '  <li>Passport</li>\n' +
            '  <li>Driving License</li>\n' +
            '  <li>Ration card</li>\n' +
            '  <li>Electricity bill</li>\n' +
            '  <li>Landline telephone bill</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '10,000 ₹',
            },
            {
              name: 'Joining fee',
              value: '10,000 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '' +
                '<ul>\n' +
                '  <li>1 complimentary Air Vistara business class ticket voucher</li>\n' +
                '  <li>Complimentary Club Vistara Gold membership</li>\n' +
                '  <li>6 Club Vistara Points every time you spend Rs. 200</li>\n' +
                '  <li>Enjoy 6 complimentary golf rounds annually</li>\n' +
                '</ul>',
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '50 000'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '50 000'
                    },
                    {
                      name: 'Age',
                      value: '21-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AXIS_CC_product_16.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'Axis bank',
          subtitle: 'Magnus card',
          tags: ['Movies', 'Travel', 'Dining', 'Shopping'],
          documents:
            '<p>The below documents are required for application:</p>\n' +
            '<ul>\n' +
            '  <li>PAN card photocopy or Form 60</li>\n' +
            '  <li>Latest payslip/ Form 16/IT return copy as proof of income</li>\n' +
            '  <li>Residence proof (Passport/Driving license/ Electricity Bill/ Landline telephone bill)</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '12,500 ₹',
            },
            {
              name: 'Joining fee',
              value: '12,500 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '' +
                '<ul>\n' +
                '  <li>Choose a Rs. 12,500 worth gift voucher from Luxe, Postcard Hotels, or Yatra gift voucher</li>\n' +
                '  <li>Buy one movie/non-movie ticket from BookMyShow and get Rs. 500 off on the next ticket</li>\n' +
                '  <li>Get an insurance shield of up to Rs. 5 lakhs</li>\n' +
                '  <li>Buy one movie/non-movie ticket from BookMyShow and get Rs. 500 off on the next ticket</li>\n' +
                '  <li>Unlimited domestic and international airport lounge access</li>\n' +
                '</ul>',
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '200 000'
                    },
                    {
                      name: 'Age',
                      value: '18-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '200 000'
                    },
                    {
                      name: 'Age',
                      value: '18-70 years'
                    },
                    {
                      name: 'Credit score',
                      value: '700+'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    blocks: [
      {
        title: 'Attractive reward points and cashback!',
        img: 'assets/AXIS_CC_gift.png',
        backgroundColor: '#FCFFDE'
      },
      {
        title: 'Accepted worldwide, wherever you go!',
        img: 'assets/AXIS_CC_earth.png',
        backgroundColor: '#F2EEFF'
      },
    ],
    rules: {
      title: 'Terms And Conditions',
      text:
        '<p>' +
        ' <b>1. For Paytm rewards:</b>' +
        '</p>' +
        '<ul>' +
        ' <li>Rewards will be transferred to your Paytm wallet after 5 to 7 days of card dispatch.</li>' +
        '</ul>' +
        '<p>' +
        ' <b>2. Eligibility for Paytm Rewards:</b>' +
        '</p>' +
        '<ul style="list-style-type: decimal">' +
        ' <li>Customers have to apply for Axis bank credit card using this link.</li>' +
        ' <li>If Customer\'s use different links from google search,youtube, other channels it will not be eligible for paytm rewards.</li>' +
        ' <li>Customer has to complete application in one go.</li>' +
        ' <li>Customer has to put correct mobile number active on his paytm wallet to get rewards.</li>' +
        ' <li>Customer has to complete application process within 10 days of time if left in between.  The customer is required to respond to the bank\'s call center calls.</li>' +
        ' <li>Eligibility criteria to apply for Axis bank Credit cards is:' +
        '   <ul style="list-style-type: lower-alpha">' +
        '     <li>Age: 21-70 years old</li>' +
        '     <li>Income criteria: 25K (Salaried) and 30K (self employed) will be preferred.</li>' +
        '     <li>Cibil score: Between 700-900 has higher chances of approval.</li>' +
        '   </ul>' +
        '</li>' +
        '</ul>'
    }
  }
}
