import { PrelanderVariant } from '../static-prelander.data'

export const AU_CC_Prelander: PrelanderVariant = {
  code: 'AU_CC',
  settings: {
    main: {
      fontClass: 'mat-headline-2',
      title: 'Apply for<br><span style="color: #528DFF">AU credit card</span>',
      img: 'assets/AU_CC_main.png',
      action: 'and get'
    },
    paytm: {
      title: 'REWARDS',
      value: 1000
    },
    products: {
      title: 'Credit cards',
      items: [
        {
          img: 'assets/AU_CC_product_01.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'AU small finance bank',
          subtitle: 'LIT Credit Card',
          tags: ['Movies', 'Dining', 'Fuel', 'Travel', 'Shopping'],
          documents:
            '<p>The documents required to apply for the AU Bank LIT Credit Card are listed below:</p>\n' +
            '<ul>\n' +
            '  <li>Identity Proof: Aadhar Card, PAN Card, Voter’s ID, Passport, or Driving License.</li>\n' +
            '  <li>Address Proof: Latest months’ utility bills, Aadhar Card, Ration card, Driving License, etc.</li>\n' +
            '  <li>Proof of Income: Latest months’ salary slips/bank statements, or latest audited ITR.</li>\n' +
            '</ul>',
          fees: [
            {
              name: 'Annual fee',
              value: 'Nil ₹',
            },
            {
              name: 'Joining fee',
              value: 'Nil ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text:
                '<ul>\n' +
                '  <li>Get 10x Reward Points on Online Spends Movies</li>\n' +
                '  <li>Get 5% Cashback on Grocery Spends</li>\n' +
                '  <li>Redeem your points for E-gift Vouchers.</li>\n' +
                '  <li>3 Months Amazon Prime Membership</li>\n' +
                '</ul>'
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Credit score',
                      value: '700+'
                    },
                    {
                      name: 'Age',
                      value: '21-60 years'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Credit score',
                      value: '700+'
                    },
                    {
                      name: 'Age',
                      value: '21-60 years'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AU_CC_product_02.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'AU small finance bank',
          subtitle: 'Altura Credit Card',
          tags: ['Dining', 'Lounge', 'Wellness', 'Online Rewards'],
          documents: '' +
            '<p>The list of documents required for the Altura Credit Credit Card is as follows:</p>\n' +
            '<ul>\n' +
            '  <li>Identity Proof: Voter’s Card, Aadhar Card, PAN Card, Passport, or Driving License.</li>\n' +
            '  <li>Address Proof: Utility bills of the latest months, Ration Card, Aadhar Card, or Passport.</li>\n' +
            '  <li>Latest three months’ salary slips/bank statements or latest audited ITR.</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '199 ₹',
            },
            {
              name: 'Joining fee',
              value: '199 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text:
                '<ul>\n' +
                '  <li>Get 2% cashback on groceries</li>\n' +
                '  <li>Get a cashback of 1% on all other spends</li>\n' +
                '  <li>Earn a cashback of Rs. 50 every statement cycle</li>\n' +
                '</ul>\n'
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Credit score',
                      value: '750+'
                    },
                    {
                      name: 'Age',
                      value: '21-60 years'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Credit score',
                      value: '750+'
                    },
                    {
                      name: 'Age',
                      value: '21-60 years'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AU_CC_product_03.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'AU small finance bank',
          subtitle: 'Altura Plus Credit Card',
          tags: ['Shopping'],
          documents: '' +
            '<p>The list of documents required for the Altura Plus Credit Credit Card is as follows:</p>\n' +
            '<ul>\n' +
            '  <li>Identity Proof: Voter’s Card, Aadhar Card, PAN Card, Passport, or Driving License.</li>\n' +
            '  <li>Address Proof: Utility bills of the latest months, Ration Card, Aadhar Card, or Passport.</li>\n' +
            '  <li>Latest three months’ salary slips/bank statements or latest audited ITR.</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '499 ₹',
            },
            {
              name: 'Joining fee',
              value: '499 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text:
                '<ul>\n' +
                '  <li>You earn 1 Reward Point on every Rs. 100</li>\n' +
                '  <li>500 bonus Reward Points</li>\n' +
                '  <li>Earn 2x Reward Points on online transactions</li>\n' +
                '</ul>\n'
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Credit score',
                      value: '750+'
                    },
                    {
                      name: 'Age',
                      value: '21-60 years'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Credit score',
                      value: '750+'
                    },
                    {
                      name: 'Age',
                      value: '21-60 years'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AU_CC_product_04.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'AU small finance bank',
          subtitle: 'Vetta Credit Card',
          tags: ['Travel', 'Shopping'],
          documents: '' +
            '<p>The list of documents required for the Vetta credit card is as follows:</p>\n' +
            '<ul>\n' +
            '  <li>Address proof: Passport, Utility Bills, Voter ID, Aadhaar Card, Ration Card, etc.</li>\n' +
            '  <li>Identity proof: Passport, Driving License, Aadhaar Card, etc.</li>\n' +
            '  <li>Income proof: Recent Salary Slips, Form 16 and Income Tax Returns.</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '2,999 ₹',
            },
            {
              name: 'Joining fee',
              value: '2,999 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '' +
                '<ul>\n' +
                '  <li>Complimentary Zomato Pro membership</li>\n' +
                '  <li>Vouchers worth Rs. 1,000</li>\n' +
                '  <li>1,000 Bonus Reward Points</li>\n' +
                '</ul>\n'
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Credit score',
                      value: '750+'
                    },
                    {
                      name: 'Age',
                      value: '21-60 years'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Credit score',
                      value: '750+'
                    },
                    {
                      name: 'Age',
                      value: '21-60 years'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AU_CC_product_05.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'AU small finance bank',
          subtitle: 'ZENITH+ CARD',
          tags: ['Shopping'],
          documents:
            '<p>The list of documents required for the Zenith plus Credit Credit Card is as follows:</p>\n' +
            '<ul>\n' +
            '  <li>Identity Proof: Voter’s Card, Aadhar Card, PAN Card, Passport, or Driving License.</li>\n' +
            '  <li>Address Proof: Utility bills of the latest months, Ration Card, Aadhar Card, or Passport.</li>\n' +
            '  <li>Latest three months’ salary slips/bank statements or latest audited ITR.</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '4,999 ₹',
            },
            {
              name: 'Joining fee',
              value: '4,999 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '' +
                '<ul>\n' +
                '  <li>You get 2% cashback on groceries, utility bill payments, and departmental stores</li>\n' +
                '  <li>You get a cashback of 1% on all other spends</li>\n' +
                '  <li>Earn a cashback of Rs. 50 every statement cycle</li>\n' +
                '</ul>\n'
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '250 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-60 years'
                    },
                    {
                      name: 'Credit score',
                      value: '750+'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Salary',
                      value: '250 000+'
                    },
                    {
                      name: 'Age',
                      value: '21-60 years'
                    },
                    {
                      name: 'Credit score',
                      value: '750+'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          img: 'assets/AU_CC_product_06.png',
          visible: true,
          bg: '#EFF5FF',
          title: 'AU small finance bank',
          subtitle: 'Zenith Credit Card',
          tags: ['Travel', 'Dining', 'Shopping'],
          documents: '' +
            '<p>The list of documents required for the Zenith credit card is as follows:</p>\n' +
            '<ul>\n' +
            '  <li>Address proof like Passport, Aadhaar Card, Utility Bills, Voter ID, Ration Card, etc.</li>\n' +
            '  <li>Identity proof like Passport, Aadhaar Card, Driving License, etc.</li>\n' +
            '  <li>Income proof such as Form 16, Income Tax Returns, and recent Salary Slips.</li>\n' +
            '</ul>\n',
          fees: [
            {
              name: 'Annual fee',
              value: '7,999 ₹',
            },
            {
              name: 'Joining fee',
              value: '7,999 ₹',
            }
          ],
          topics: [
            {
              type: 'text',
              title: 'Benefits',
              text: '' +
                '<ul>\n' +
                '  <li>Zomato Pro membership</li>\n' +
                '  <li>Brand vouchers worth Rs. 1000</li>\n' +
                '  <li>20 Reward Points for every Rs. 100 spent</li>\n' +
                '</ul>\n'
            },
            {
              type: 'requirements',
              title: 'Requirements for clients',
              groups: [
                {
                  group: 'Salaried employee',
                  items: [
                    {
                      name: 'Credit score',
                      value: '750+'
                    },
                    {
                      name: 'Age',
                      value: '21-60 years'
                    }
                  ]
                },
                {
                  group: 'Self employee',
                  items: [
                    {
                      name: 'Credit score',
                      value: '750+'
                    },
                    {
                      name: 'Age',
                      value: '21-60 years'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    blocks: [
      {
        title: 'Lower foreign currency markup!',
        img: 'assets/AU_CC_wallet.png',
        backgroundColor: '#FCFFDE'
      },
      {
        title: 'International Lounge Access with Priority Pass!',
        img: 'assets/AU_CC_earth.png',
        backgroundColor: '#F2EEFF'
      },
      {
        title: 'Enjoy complimentary movie tickets every month!',
        //subtitle: 'Strike gold when you withdraw. Literally',
        img: 'assets/AU_CC_netflix.png',
        backgroundColor: '#EBFAFF'
      },
    ],
    rules: {
      title: 'Terms And Conditions',
      text:
        '<p>' +
        ' <b>1. For Paytm rewards:</b>' +
        '</p>' +
        '<ul>' +
        ' <li>Rewards will be transferred to your Paytm wallet after 5 to 7 days of card dispatch.</li>' +
        '</ul>' +
        '<p>' +
        ' <b>2. Eligibility for Paytm Rewards:</b>' +
        '</p>' +
        '<ul style="list-style-type: decimal">' +
        ' <li>Customers have to apply for AU small finance bank credit card using this link.</li>' +
        ' <li>If Customer\'s use different links from google search,youtube, other channels it will not be eligible for paytm rewards.</li>' +
        ' <li>Customer has to complete application in one go.</li>' +
        ' <li>Customer has to put correct mobile number active on his paytm wallet to get rewards.</li>' +
        ' <li>Customer has to complete application process within 10 days of time if left in between.</li>' +
        ' <li>Eligibility criteria to apply for AU small finance bank Credit cards is:' +
        '   <ul style="list-style-type: lower-alpha">' +
        '     <li>Age: 21-70 years old</li>' +
        '     <li>Income criteria: 25K (Salaried) and 30K (self employed) will be preferred.</li>' +
        '     <li>Cibil score: Between 700-900 has higher chances of approval.</li>' +
        '   </ul>' +
        '</li>' +
        '</ul>'
    }
  }
}
