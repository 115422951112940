<section class="fab">
  <button mat-mini-fab aria-label="close" (click)="close()" [disabled]="isLoading">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</section>
<section class="dialog">
  <section class="header mat-headline-5">Documents</section>
  <!--<section class="number mat-headline-2"></section>-->
  <section class="info" [innerHTML]="data.text ? (data.text | safe: 'html') : ''">
  </section>
</section>
