import {Component} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { environment } from '../environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor (private matIconRegistry: MatIconRegistry,
               private domSanitizer: DomSanitizer,) {
    //todo transfer to IconService
    const icons = [
      'arrow', 'close', 'expand_more', 'expand_less', 'create', 'done'
    ]
    icons.forEach(icon => this.matIconRegistry.addSvgIcon(
      `${icon}`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/${icon}.svg`)
    ))
  }

  protected readonly environment = environment
}
