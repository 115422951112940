import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { catchError, delay, share, startWith } from 'rxjs/operators'
import { PreLandingData } from '../api/offer.service'
import { Dictionary, DictionaryValue } from '../api/DIctionary'
import { combineLatest, finalize, forkJoin, Observable, of, tap } from 'rxjs'
import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common'
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { SharedModule } from '../shared/shared.module'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatStepperModule } from '@angular/material/stepper'
import { NgxMaskModule } from 'ngx-mask'
import { ActivatedRoute } from '@angular/router'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { FadeInAnimation, FadeOutAnimation, Slide } from '../shared/functions/animations.function'
import { Offer } from '../model/offer'

@Component({
  standalone: true,
  selector: 'app-business-loans-prelander',
  templateUrl: './business-loans-prelander.component.html',
  imports: [
    AsyncPipe,
    NgIf,
    JsonPipe,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgForOf,
    SharedModule,
    MatButtonModule,
    MatIconModule,
    MatStepperModule,
    NgxMaskModule,
    MatProgressSpinnerModule,
  ],
  animations: [
    Slide(),
    FadeInAnimation(),
    FadeOutAnimation(),
  ],
  styleUrls: ['./business-loans-prelander.component.scss']
})
export class BusinessLoansPrelanderComponent implements OnInit {

  data!: Observable<{dictionary: Dictionary[], offer: Partial<Offer>}>
  step_01!: FormGroup
  step_02!: FormGroup

  offerId!: number
  trafficSourceId!: number
  extUrlParameter: string | null = null
  personal!: boolean

  isProcessing: boolean = false
  showDetails: 'in' | 'out' = 'in'
  showDetailsAlways = 'in'
  partnersLogo = [
    'assets/loan_logo_01.JPG',
    'assets/loan_logo_02.JPG',
    'assets/loan_logo_03.JPG',
  ]

  constructor (private httpClient: HttpClient,
               private route: ActivatedRoute,
               private fb: FormBuilder) {

  }


  ngOnInit () {
    this.offerId = this.route.snapshot.queryParams['offerId'] && Number(this.route.snapshot.queryParams['offerId'])
    this.trafficSourceId = this.route.snapshot.queryParams['trafficSourceId'] && Number(this.route.snapshot.queryParams['trafficSourceId'])
    this.extUrlParameter = this.route.snapshot.queryParams['extUrlParameter']
    this.personal = !!this.route.snapshot.data['personal']

    const dictionaryUrlApi = `${environment.backendEndpoint}/public/dictionary`
    const offerUrl = `${environment.backendEndpoint}/public/landing?offerId=${this.offerId}`


    this.step_01 = this.fb.group({
      loanAmount: [null, Validators.required],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phoneNumber: [null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      email: [null, Validators.required],
      residenceType: [null, Validators.required],
    })
    this.step_02 = this.fb.group({
      businessName: [null, Validators.required],
      companyType: [null, Validators.required],
      annualSales: [null, Validators.required],
      officeShopType: [null, Validators.required],
      businessAge: [null, Validators.required],
      officeShopPincode: [null, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6)
      ]],
      individualPanCard: [null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)]],
    })

    this.data = forkJoin({
      dictionary: this.httpClient.get<Dictionary[]>(dictionaryUrlApi).pipe(),
      offer: this.offerId ? this.httpClient.get<Offer>(offerUrl) : of({})
    })
  }

  send () {
    const postUrl = `${environment.backendEndpoint}/public/businessLoanLanding`
    if (this.step_01 .valid && this.step_02 .valid) {
      this.httpClient.post(postUrl, {
        offerId: this.offerId,
        trafficSourceId: this.trafficSourceId,
        extUrlParams: this.extUrlParameter,
        ...this.step_01.getRawValue(),
        ...this.step_02.getRawValue(),
      })
      .pipe(
        startWith(() => this.isProcessing = true),
        delay(2000),
        finalize(() => this.isProcessing = false))
      .subscribe(res => {
        debugger
      })
    }
  }
}
