<mat-toolbar class="bg-color-card" *ngIf="false">
  <img src="assets/cashli_logo_without_text.svg" alt=""/>
</mat-toolbar>

<section *ngIf="offerId">
  <section *ngIf="offer$ | loading:100 | async as result">
    <section class="loading" *ngIf="result.loading">
      <mat-spinner color="accent"></mat-spinner>
    </section>
    <section class="error" *ngIf="result.error">
      <pre>{{(result.error?.error?.failure || result.error) | json}}</pre>
    </section>
    <form class="content" *ngIf="result.value" [formGroup]="form">
      <mat-card>
        <mat-card-header *ngIf="!hideLogo">
          <img [src]="result.value?.brandUrl">
          <mat-card-title class="mat-headline-5">{{result.value?.title}}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="form">
          <mat-form-field appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <section style="display: flex; flex-direction: row;">
              <div class="country-code mat-body-2" style="width: 100px">
                <span style="margin-right: 10px">🇮🇳</span>
                <span>+91</span>
              </div>
              <input matInput formControlName="phoneNumber"
                     type="text" mask="00 0000 0000"
                     placeholder="Enter Your Phone">
            </section>
          </mat-form-field>
          <mat-form-field *ngIf="!hidePanCard"
                          appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <input matInput formControlName="pan"
                   #pan
                   type="text" mask="SSSSS0000S"
                   placeholder="Enter Your PAN">
          </mat-form-field>
          <mat-form-field appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <input matInput formControlName="name" placeholder="Your Full Name">
          </mat-form-field>
          <mat-form-field *ngIf="!hideEmail"
                          appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <input matInput formControlName="email" placeholder="Your E-Mail Address">
          </mat-form-field>
          <mat-form-field class="pin-code"
                          appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <input matInput formControlName="pinCode"
                   type="text" mask="000 000"
                   placeholder="Enter Pincode">
          </mat-form-field>
          <!--
          <mat-checkbox class="checkbox mat-body-1" formControlName="accept">
            By clicking this checkbox, I authorize Cashli to securely store and use my data to contact me via call / SMS / WhatsApp / email about its products, and I confirm that I have accepted the <a href="http://cashli.in/termsandconditions">terms</a> of the <a href="http://cashli.in/privacy">privacy policy</a>
          </mat-checkbox> -->
          <mat-checkbox class="checkbox mat-body-1" formControlName="accept">
            By clicking on this check box, I agree to the <a href="http://cashli.in/termsandconditions">terms</a> of the <a href="http://cashli.in/privacy">privacy policy</a>
          </mat-checkbox>

          <button mat-raised-button color="accent"
                  class="button" (click)="submit()">
            <mat-spinner *ngIf="isLoading" color="primary"></mat-spinner>
            <span *ngIf="!isLoading">Start Now</span>
          </button>
        </mat-card-content>
      </mat-card>
      <mat-card class="info">
        <mat-card-header *ngIf="!hideLogo">
          <mat-card-title class="mat-headline-5">{{result.value?.title}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-tab-group color="accent">
            <mat-tab label="Benefits">
              <h2 class="mat-headline-6">Features</h2>
              <p class="mat-body-1" [innerHTML]="result.value?.features"></p>
              <h2 class="mat-headline-6">Fees & charges</h2>
              <p class="mat-body-1" [innerHTML]="result.value?.feesAndCharges"></p>
            </mat-tab>
            <mat-tab label="Documents">
              <h2 class="mat-headline-6">Documents Required</h2>
              <p class="mat-body-1" [innerHTML]="result.value?.requiredDocuments"></p>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </mat-card>
    </form>
    <section class="mat-caption color-primary-darker copyright">
      © 2024 All Rights Reserved
    </section>
  </section>
</section>
