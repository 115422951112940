<mat-card>
  <mat-card-content>
    <section class="header">
      <img [src]="product.img" />
      <div class="info">
        <p class="title">{{product.title}}</p>
        <p class="subtitle">{{product.subtitle}}</p>
      </div>
    </section>
    <section class="tags">
      <div color="primary" *ngFor="let tag of product.tags">{{tag}}
      </div>
    </section>
    <button mat-stroked-button color="primary"
            (click)="docDetails(product.documents)">Documents
    </button>
    <div class="fees">
      <p *ngFor="let fee of product.fees" class="mat-body-1">
        <span class="name">{{fee.name}}</span>
        <span>{{fee.value}}</span>
      </p>
    </div>

    <cdk-accordion class="example-accordion" multi>
      <cdk-accordion-item
        *ngFor="let topic of product.topics; let index = index;"
        #accordionItem="cdkAccordionItem"
        class="example-accordion-item"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-' + index"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-' + index">
        <div class="accordion-item-header"
             (click)="$event.stopImmediatePropagation();$event.stopPropagation();accordionItem.toggle()">
          <div class="mat-body-2" style="font-size: large; font-weight: 500;">
            {{ topic.title }}
          </div>
          <mat-icon class="accordion-item-action"
                    [svgIcon]="accordionItem.expanded ? 'expand_less' : 'expand_more'">
          </mat-icon>
        </div>
        <div class="accordion-item-body"
             role="region"
             [style.display]="accordionItem.expanded ? '' : 'none'"
             [attr.id]="'accordion-body-' + index"
             [attr.aria-labelledby]="'accordion-header-' + index">
          <div *ngIf="topic.type === 'text'"
               [innerHTML]="topic.text | safe:'html'">
          </div>
          <div *ngIf="topic.type === 'requirements'">
            <div *ngFor="let requirement of topic.groups"
                 class="requirements-group">
              <div class="group-name">{{requirement.group}}</div>
              <div class="requirements-grid">
                <p class="item" *ngFor="let item of requirement.items">
                  <span class="name">{{item.name}}</span>
                  <span>{{item.value}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </cdk-accordion-item>
    </cdk-accordion>
  </mat-card-content>
</mat-card>
