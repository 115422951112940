import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RootPrelanderComponent } from './root-prelander/root-prelander.component'
import { StaticPrelanderComponent } from './static-prelander/static-prelander.component'
import { BusinessLoansPrelanderComponent } from './business-loans-prelander/business-loans-prelander.component' // CLI imports router
import { CashbackPrelanderComponent } from './cashback-prelander/cashback-prelander.component'
import { PersonalLoanPrelanderComponent } from './personal-loan-prelander/personal-loan-prelander.component' // CLI imports router

const routes: Routes = [
  { path: 'static/:variant', component: StaticPrelanderComponent },
  { path: 'bezlg', component: RootPrelanderComponent, data: { hideLogo: true } },
  { path: 'bgm/bl', component: BusinessLoansPrelanderComponent },
  { path: 'bgm/pl', component: PersonalLoanPrelanderComponent },
  { path: 'bgm/bl/prs', component: BusinessLoansPrelanderComponent, data: { personal: true }},
  { path: 'bgm/pl/prs', component: PersonalLoanPrelanderComponent,data: { personal: true } },
  { path: 'cashback', component: CashbackPrelanderComponent},
  // https://gitlab.com/cashli/cashlisub/common-board/-/issues/164
  { path: 'bezempc', component: RootPrelanderComponent, data: { hideEmail: true, hidePanCard: true }},
  { path: 'bezpc', component: RootPrelanderComponent,  data: { hidePanCard: true }}
]; // sets up routes constant where you define your routes

// configures NgModule imports and exports
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
