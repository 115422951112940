import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Product } from '../../../static-prelander/static-prelander.data'
import { DocumentListComponent } from '../document-list/document-list.component'
import { MatBottomSheet } from '@angular/material/bottom-sheet'

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  //encapsulation: ViewEncapsulation.None,
})
export class ProductCardComponent {
  @Input() product!: Product

  constructor (private bottomSheet: MatBottomSheet) {
  }
  docDetails(text: string): void {
    this.bottomSheet.open(DocumentListComponent, {data: { text }});
  }
}
