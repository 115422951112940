import { Pipe, PipeTransform } from '@angular/core';
import { Dictionary, DictionaryValue } from '../../api/DIctionary'

@Pipe({
  name: 'dictionaryValues'
})
export class DictionaryValuesPipe implements PipeTransform {

  transform(code: string, dictionaries: Dictionary[]): DictionaryValue[] {
    return dictionaries.find(d => d.code === code)?.values || []
  }

}
