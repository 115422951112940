import { Component, OnInit } from '@angular/core';
import { combineLatest, finalize, forkJoin, Observable, of } from 'rxjs'
import { Dictionary } from '../api/DIctionary'
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { ActivatedRoute } from '@angular/router'
import { delay, startWith } from 'rxjs/operators'
import { MatStepperModule } from '@angular/material/stepper'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { AsyncPipe, NgForOf, NgIf } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { NgxMaskModule } from 'ngx-mask'
import { MatSelectModule } from '@angular/material/select'
import { SharedModule } from '../shared/shared.module'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import { FadeInAnimation, FadeOutAnimation, Slide } from '../shared/functions/animations.function'
import { MatIconModule } from '@angular/material/icon'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { Offer } from '../model/offer'

@Component({
  standalone: true,
  selector: 'app-personal-loan-prelander',
  templateUrl: './personal-loan-prelander.component.html',
  imports: [
    MatStepperModule,
    MatProgressSpinnerModule,
    NgIf,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    NgxMaskModule,
    MatSelectModule,
    SharedModule,
    NgForOf,
    AsyncPipe,
    MatDatepickerModule,
    MatIconModule
  ],
  providers: [
    /**
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false},
    },**/
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: "DD/MM/YYYY",
        },
        display: {
          dateInput: "MMM DD, YYYY",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
        },
      }
    }],
  animations: [
    Slide(),
    FadeInAnimation(),
    FadeOutAnimation(),
  ],
  styleUrls: ['./personal-loan-prelander.component.scss']
})
export class PersonalLoanPrelanderComponent implements OnInit {
  data!: Observable<{dictionary: Dictionary[], offer: Partial<Offer>}>
  step_01!: FormGroup
  step_02!: FormGroup

  offerId!: number
  trafficSourceId!: number
  extUrlParameter: string | null = null
  personal!: boolean

  isProcessing: boolean = false
  showDetails: 'in' | 'out' = 'in'
  showDetailsAlways = 'in'
  partnersLogo = [
    'assets/loan_logo_01.JPG',
    'assets/loan_logo_02.JPG',
    'assets/loan_logo_03.JPG',
  ]

  constructor (private httpClient: HttpClient,
               private route: ActivatedRoute,
               private fb: FormBuilder) {

  }

  ngOnInit () {
    this.offerId = this.route.snapshot.queryParams['offerId'] && Number(this.route.snapshot.queryParams['offerId'])
    this.trafficSourceId = this.route.snapshot.queryParams['trafficSourceId'] && Number(this.route.snapshot.queryParams['trafficSourceId'])
    this.extUrlParameter = this.route.snapshot.queryParams['extUrlParameter']
    this.personal = !!this.route.snapshot.data['personal']


    const dictionaryUrlApi = `${environment.backendEndpoint}/public/dictionary`
    const offerUrl = `${environment.backendEndpoint}/public/landing?offerId=${this.offerId}`

    this.step_01 = this.fb.group({
      personalLoanAmount: [null, Validators.required],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phoneNumber: [null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      email: [null, Validators.required],
      dateOfBirth: [null, Validators.required],
      gender: [null, Validators.required],
    })
    this.step_02 = this.fb.group({
      companyName: [null, Validators.required],
      residencePincode: [null, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6)
      ]],
      panCard: [null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)]],
      experience: [null, Validators.required],
      netSalary: [null, Validators.required],
      residenceType: [null, Validators.required],
    })

    this.data = forkJoin({
      dictionary: this.httpClient.get<Dictionary[]>(dictionaryUrlApi).pipe(),
      offer: this.offerId ? this.httpClient.get<Offer>(offerUrl) : of({})
    })
  }

  send () {
    const postUrl = `${environment.backendEndpoint}/public/personalLoanLanding`
    if (this.step_01.valid && this.step_02.valid) {
      this.httpClient.post(postUrl, {
        offerId: this.offerId,
        trafficSourceId: this.trafficSourceId,
        extUrlParams: this.extUrlParameter,
        ...this.step_01.getRawValue(),
        ...this.step_02.getRawValue(),
      })
      .pipe(
        startWith(() => this.isProcessing = true),
        delay(2000),
        finalize(() => this.isProcessing = false))
      .subscribe(res => {
        debugger
      })
    }
  }
}


