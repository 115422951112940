import { Component } from '@angular/core';
import { finalize, Observable, tap } from 'rxjs'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { OfferService } from '../api/offer.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { LoadingDialogComponent } from '../shared/components/loading-dialog/loading-dialog.component'
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router'

@Component({
  selector: 'app-root-prelander',
  templateUrl: './root-prelander.component.html',
  styleUrls: ['./root-prelander.component.scss']
})
export class RootPrelanderComponent {
  title = 'prelander';
  offerId!: number;
  trafficSourceId!: number;
  cashbackUrlId!: number
  redirectUrl!: string;
  extUrlParameter: string | null = null;
  offer$!: Observable<any>;
  form!: FormGroup;
  hideLogo = false
  hideEmail = false
  hidePanCard = false
  isLoading = false

  constructor(public dialog: MatDialog,
              public offerService: OfferService,
              private fb: FormBuilder,
              private ar: ActivatedRoute,
              private snack: MatSnackBar,) {
  }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search)
    this.offerId = Number(urlParams.get('offerId'))
    this.trafficSourceId = Number(urlParams.get('tsId'))
    this.cashbackUrlId = Number(urlParams.get('cashbackUrlId'))
    this.extUrlParameter = urlParams.get('ext')
    this.hideLogo = this.ar.snapshot.data.hasOwnProperty('hideLogo') ? this.ar.snapshot.data['hideLogo'] : false
    this.hideEmail = this.ar.snapshot.data.hasOwnProperty('hideEmail') ? this.ar.snapshot.data['hideEmail'] : false
    this.hidePanCard = this.ar.snapshot.data.hasOwnProperty('hidePanCard') ? this.ar.snapshot.data['hidePanCard'] : false

    if (this.offerId) {
      this.offer$ = this.offerService.getById(this.offerId, this.cashbackUrlId)
      this.initForm()
    }
  }

  initForm() {
    this.form = this.fb.group({
      offerId: [this.offerId],
      trafficSourceId: [this.trafficSourceId],
      phoneNumber: [undefined, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      name: [undefined, [Validators.required, Validators.minLength(5)]],
      pan: [undefined, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)]],
      email: [undefined, [Validators.required, Validators.email]],
      extUrlParams: [this.extUrlParameter],
      pinCode: [undefined, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6)
      ]],
      accept: [true, Validators.requiredTrue]
    })
    if (this.hideEmail) {
      this.form.removeControl('email', {emitEvent: false})
    }
    if (this.hidePanCard) {
      this.form.removeControl('pan', {emitEvent: false})
    }

    this.form.get('pan')?.valueChanges.subscribe(v =>
      this.form.get('pan')?.patchValue(v.toUpperCase(), {emitEvent: false}))
  }

  submit() {
    if (this.form.invalid || this.isLoading) {
      //this.snack.open("Invalid form controls")
      return
    } else {
      this.form.disable()
      this.isLoading = true
      const request = this.form.getRawValue()
      delete request.accept
      this.offerService.submitRequest(request)
      .pipe(
        tap({
          next: (r) => {
            //this.initForm(a)
            debugger
            location.href = r.redirectUrl
            // this.openLoadingDialog(r.redirectUrl)
            //this.snack.open("Object successfully saved", "Dismiss")
            //this.router.navigate(['../'], {relativeTo: this.activatedRoute}).then()
          },
          error: e => {
            this.snack.open(e?.error?.failure || e?.error || e, "Dismiss", {
              duration: 30000
            })
          }
        }),
        finalize(() => {
          this.isLoading = false
          this.form.enable()
        }))
      .subscribe()
    }
  }

  openLoadingDialog(redirectUrl?: string, delay = 5) {
    const dialogRef = this.dialog.open(LoadingDialogComponent, {
      panelClass: "cashli",
      closeOnNavigation: false,
      disableClose: true,
      //width: "60vw",
      //maxWidth: "60vw",
      //minHeight: "50vh",
      data: {
        redirectUrl,
        delay
      }
    })
    dialogRef.afterClosed().subscribe(goalUpd => {
    })
  }
}

