import { AXIS_CC_Prelander } from './data/AXIS_CC'
import { AU_CC_Prelander } from './data/AU_CC'

export interface Product {
  visible: boolean;
  bg: string;
  img: string;
  title: string;
  subtitle: string;
  tags: string[];
  documents: string;
  fees: {
    name: string;
    value: string;
  }[];
  topics: {
    type: 'text' | 'requirements'
    title: string;
    text?: string;
    groups?: {
      group: string;
      items: {
        name: string;
        value: string;
      }[]
    }[];
  }[];
};

export interface PrelanderVariant {
  code: string;
  settings: {
    main: {
      fontClass?: string;
      title: string;
      subtitle?: string;
      img: string;
      action: string;
    };
    paytm: {
      title: string;
      value: number | string;
    };
    products?: {
      title: string;
      items: Product[]
    };
    blocks: {
      title: string;
      subtitle?: string;
      img: string;
      backgroundColor: string;
      withoutBottom?: true;
    }[];
    rules: {
      title: string;
      text: string;
    }
  };
}

export const PRELANDER_VARIANTS: PrelanderVariant[] = [
  {
    code: 'IB_CC',
    settings: {
      main: {
        title: '<p>Apply for <span style="color: #528DFF">Indusind bank credit card</span></p>',
        img: 'assets/IB_CC_Main.png',
        action: 'and get'
      },
      paytm: {
        title: 'REWARDS',
        value: 1000
      },
      blocks: [
        {
          title: 'Attractive reward points and cashback!',
          //subtitle: 'Get cash in your bank whenever you wish',
          img: 'assets/IB_CC_Gift.png',
          backgroundColor: '#FCFFDE'
        },
        {
          title: 'Accepted worldwide, wherever you go!',
          //subtitle: 'That\'s our guarantee',
          img: 'assets/IB_CC_Earth.png',
          backgroundColor: '#F2EEFF'
        },
        {
          title: 'Enjoy complimentary movie tickets every month!',
          //subtitle: 'Strike gold when you withdraw. Literally',
          img: 'assets/IB_CC_Netflix.png',
          backgroundColor: '#EBFAFF'
        },
        {
          title: 'Bonus points just for you!',
          //subtitle: 'Saving money has never been easier',
          img: 'assets/IB_CC_Boy.png',
          backgroundColor: '#FFF9C3'
        }
      ],
      rules: {
        title: 'Terms And Conditions',
        text: '<p><b>For Paytm rewards:</b></p>\n' +
          '          <ul>\n' +
          '            <li>Rewards will be transferred to your Paytm wallet after 5 to 7 days of card dispatch.</li>\n' +
          '          </ul>\n' +
          '          <p><b>Eligibility for Paytm Rewards:</b></p>\n' +
          '          <ul>\n' +
          '            <li>Customer has to apply for Indusind Bank Credit Cards using this Link.</li>\n' +
          '            <li>Customer should be new to Indusind Bank.</li>\n' +
          '            <li>Customer has to complete the application within 30 days of starting the application process.</li>\n' +
          '            <li>Eligibility criteria: FOR SALARIED-> Age Group: 21-65 years & Income Range: ₹20,000+ ; FOR SELF EMPLOYED -> Age Group: 21-70 years & have a stable source of income</li>\n' +
          '          </ul>'
      }
    }
  },
  {
    code: 'ACKO_CI',
    settings: {
      main: {
        title: '<p>Buy insurance <span style="color: #528DFF">for your car</span></p>',
        img: 'assets/ACKO_CI_Main.png',
        action: 'get back'
      },
      paytm: {
        title: 'REWARDS',
        value: '15%'
      },
      blocks: [
        {
          title: 'Save upto 85%* on car insurance premium',
          //subtitle: 'Get cash in your bank whenever you wish',
          img: 'assets/ACKO_CI_Premium.png',
          backgroundColor: '#FCFFDE'
        },
        {
          title: 'Zero commission',
          //subtitle: 'That\'s our guarantee',
          img: 'assets/ACKO_CI_Zero.png',
          backgroundColor: '#F2EEFF'
        },
        {
          title: 'Zero paperwork',
          //subtitle: 'Strike gold when you withdraw. Literally',
          img: 'assets/ACKO_CI_Contract.png',
          backgroundColor: '#EBFAFF'
        },
        {
          title: 'Rated 4.8/5 on Google',
          //subtitle: 'Saving money has never been easier',
          img: 'assets/ACKO_CI_Trophy.png',
          backgroundColor: '#FFF9C3'
        }
      ],
      rules: {
        title: 'Terms And Conditions',
        text: '<p><b>Regarding Your Rewards:</b></p>\n' +
          '          <ul>\n' +
          '            <li>Rewards will be transferred to your Paytm wallet within 5 days of placing the order.</li>\n' +
          '          </ul>\n' +
          '          <p><b>Your Rewards is applicable only if:</b></p>\n' +
          '          <ul>\n' +
          '            <li>Customer should buy policy from the website.</li>\n' +
          '            <li>Customer should be new to Acko</li>\n' +
          '            <li>Customer should be 18 and more years old.</li>\n' +
          '            <li>Comprehensive policy purchases will be eligible for Paytm rewards only.</li>\n' +
          '          </ul>'
      }
    }
  },
  {
    code: 'JAR',
    settings: {
      main: {
        title: '<p>Buy gold at least <span style="color: #528DFF">for 15 rupees</span></p>',
        img: 'assets/JAR_Main.png',
        action: 'and get'
      },
      paytm: {
        title: 'REWARDS',
        value: 15
      },
      blocks: [
        {
          title: 'Super simple withdrawals',
          subtitle: 'Get cash in your bank whenever you wish',
          img: 'assets/JAR_Wallet.png',
          backgroundColor: '#FCFFDE'
        },
        {
          title: '100% safe',
          subtitle: 'That\'s our guarantee',
          img: 'assets/JAR_Safe.png',
          backgroundColor: '#F2EEFF'
        },
        {
          title: 'Doorstep Gold delivery',
          subtitle: 'Strike gold when you withdraw. Literally',
          img: 'assets/JAR_Golden.png',
          withoutBottom: true,
          backgroundColor: '#EBFAFF'
        },
        {
          title: 'Transactions? Many options.',
          subtitle: 'Saving money has never been easier',
          img: 'assets/JAR_Transaction.png',
          withoutBottom: true,
          backgroundColor: '#FFF9C3'
        }
      ],
      rules: {
        title: 'Terms And Conditions',
        text: '<p><b>Regarding Your rewards:</b></p>\n' +
          '          <ul>\n' +
          '            <li>Rewards will be transferred to your Paytm wallet within 5 days of placing the order.</li>\n' +
          '          </ul>\n' +
          '          <p><b>Your rewards is applicable only if:</b></p>\n' +
          '          <ul>\n' +
          '            <li>Customer should buy gold from the website.</li>\n' +
          '            <li>Customer should be 18 and more years old.</li>\n' +
          '            <li>The minimum purchase value should be 15 rupees.</li>\n' +
          '            <li>Customer should be a new user of Jar</li>\n' +
          '          </ul>'
      }
    }
  },
  {
    code: 'ACKO',
    settings: {
      main: {
        title: '<p>Buy insurance for your bike</p>',
        subtitle: '<p style="color: #528DFF">Bike insurance starting at ₹555*</p>',
        img: 'assets/ACKO_main.png',
        action: 'and get'
      },
      paytm: {
        title: 'REWARDS',
        value: 150
      },
      blocks: [
        {
          title: 'More than 8 crore Indians trust Acko for their insurance needs',
          img: 'assets/ACKO_block_01.png',
          backgroundColor: '#FCFFDE'
        },
        {
          title: '3 out of 4 customers renew their bike insurance policy with ACKO',
          img: 'assets/ACKO_block_02.png',
          backgroundColor: '#F2EEFF'
        },
        {
          title: 'ACKO has happy customers in 647 cities & towns across India',
          img: 'assets/ACKO_block_03.png',
          backgroundColor: '#EBFAFF'
        },
        {
          title: 'Rated 4.8/5 on Google',
          img: 'assets/ACKO_block_04.png',
          backgroundColor: '#FFF9C3'
        }
      ],
      rules: {
        title: 'Terms And Conditions',
        text: '<p><b>Regarding Your rewards:</b></p>\n' +
          '          <ul>\n' +
          '            <li>Rewards will be transferred to your Paytm wallet within 5 days of placing the order.</li>\n' +
          '          </ul>\n' +
          '          <p><b>Your rewards is applicable only if:</b></p>\n' +
          '          <ul>\n' +
          '            <li>Customer should buy policy from the website.</li>\n' +
          '            <li>Customer should be 18 and more years old.</li>\n' +
          '            <li>Customer should a new user for Acko.</li>\n' +
          '            <li>Customer should not have a valid insurance when the customer buys a new one.</li>\n' +
          '            <li>Comprehensive policy purchases will be eligible for Paytm rewards only.</li>\n' +
          '          </ul>'
      }
    }
  },
  { ...AXIS_CC_Prelander },
  { ...AU_CC_Prelander },
  Object.assign(
    { ...AXIS_CC_Prelander },
    { code: 'AXIS700_CC' },
    {
      settings: Object.assign(
        { ...AXIS_CC_Prelander.settings },
        { paytm: { ...AXIS_CC_Prelander.settings.paytm, value: 700 } }
      )
    }),
]
