import { Component, Inject, Input } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { finalize, tap } from 'rxjs'
import { OfferService, PreLandingSubmitRequest } from '../../../api/offer.service'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'app-confirm-bottom',
  templateUrl: './confirm-bottom.component.html',
  styleUrls: ['./confirm-bottom.component.scss']
})
export class ConfirmBottomComponent {
  isLoading = false;
  constructor (private bottomSheetRef: MatBottomSheetRef<ConfirmBottomComponent>,
               @Inject(MAT_BOTTOM_SHEET_DATA) public data: PreLandingSubmitRequest,
               public offerService: OfferService,
               private snack: MatSnackBar) {
  }

  confirm() {
    this.isLoading = true
    this.offerService.submitRequest(this.data)
    .pipe(
      tap({
        next: (r) => {
          location.href = r.redirectUrl
        },
        error: e => {
          this.snack.open(e?.error?.failure || e?.error || e, "Dismiss", {
            duration: 30000
          })
          this.isLoading = false
        }
      }),
      finalize(() => {}))
    .subscribe()
  }

  close() {
    this.bottomSheetRef.dismiss()
  }
}
