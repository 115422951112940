import { animate, AnimationMetadata, state, style, transition, trigger } from '@angular/animations'

export function FadeInAnimation(sec: number = 0.4, y: number = 5, x: number =0, scale: number = 1): AnimationMetadata {
  return trigger('fadeIn', [
    transition(':enter', [
      style({
        opacity: 0,
        transform: `translateY(${y}px) translateX(${x}px) scale(${scale})`,
      }),
      animate(sec + 's ease-in', style({
        opacity: 1,
        transform: `translateY(${0}px) translateX(${0}px) scale(1)`,
      }))
    ])
  ])
}
export function FadeOutAnimation(sec: number = 0.4, y: number = 10, x: number =0,): AnimationMetadata {
  return trigger('fadeOut', [
    transition(':leave', [
      style({
        opacity: 1
      }),
      animate(sec + 's ease-out', style({
        opacity: 0
      }))
    ])
  ])
}

export function Slide(): AnimationMetadata {
  return trigger('slideInOut', [
    state('in', style({
      overflow: 'hidden',
      height: '{{height}}px',
      minHeight: '{{height}}px',
      //width: '300px'
    }), {params: {height: 360}}),
    state('out', style({
      opacity: '0',
      overflow: 'hidden',
      height: '0px',
      minHeight: '0px',
      //width: '0px'
    })),
    transition('in => out', animate('200ms ease-in-out')),
    transition('out => in', animate('200ms ease-in-out'))
  ])
}
