<section class="stepper-form-container" *ngIf="(data | async) as res; else loading">
  <section class="content">
    <section class="details" [@slideInOut]="{value: showDetailsAlways, params: {height: personal ? 230 : 260}}">
      <img *ngIf="!personal" class="logo" src="assets/bl.jpg" alt="logo" />
      <img *ngIf="personal" class="logo" style="padding: 1rem" [src]="res.offer.brandUrl" alt="logo" />
      <h3  *ngIf="!personal" class="mat-typography title" style="padding: 1rem">Find the best Business Loan offer for which you are
        eligible</h3>
      <h3 *ngIf="personal" class="mat-typography title" style="padding: 1rem">Apply for {{ res.offer.title}}</h3>
      <section *ngIf="!personal" class="partners-logo">
        <img *ngFor="let logo of partnersLogo" [src]="logo" alt="">
      </section>
    </section>
    <mat-stepper [linear]="true" #stepper color="accent">
      <mat-step [stepControl]="step_01" [formGroup]="step_01">
        <ng-template matStepLabel>Request</ng-template>
        <section class="form-grid">
          <mat-form-field style="grid-column-end: span 2"
                          appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Loan Amount In Rupees</mat-label>
            <input (focusin)="showDetails = 'out'" (focusout)="showDetails = 'in'"
                   matInput formControlName="loanAmount" placeholder="Amount in rupees" type="number">
            <span matTextSuffix>&#8377;</span>
          </mat-form-field>
          <mat-form-field appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName"
                   (focusin)="showDetails = 'out'" (focusout)="showDetails = 'in'"
                   placeholder="Your First Name">
          </mat-form-field>
          <mat-form-field appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName"
                   (focusin)="showDetails = 'out'" (focusout)="showDetails = 'in'"
                   placeholder="Your Last Name">
          </mat-form-field>

          <mat-form-field appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Phone Number</mat-label>
            <input matInput
                   formControlName="phoneNumber"
                   (focusin)="showDetails = 'out'" (focusout)="showDetails = 'in'"
                   mask="00 0000 0000"
                   placeholder="Your Phone">
            <span matTextPrefix>+91&nbsp;</span>
          </mat-form-field>
          <mat-form-field appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Email Id</mat-label>
            <input matInput formControlName="email"
                   (focusin)="showDetails = 'out'" (focusout)="showDetails = 'in'"
                   placeholder="Your Email">
          </mat-form-field>

          <mat-form-field style="grid-column-end: span 2"
                          appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Residence Type</mat-label>
            <mat-select formControlName="residenceType"
                        (focusin)="showDetails = 'out'" (focusout)="showDetails = 'in'"
                        placeholder="Your Residence Type">
              <mat-option *ngFor="let item of ('RESIDENCE_TYPE' | dictionaryValues:res.dictionary)" [value]="item">
                {{ item.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </section>
        <div style="flex: auto">
          <!--
          <pre>valid={{step_01.valid}}</pre>
          <pre>valid={{step_01.getRawValue() | json}}</pre>-->
        </div>
        <div class="buttons">
          <button class="next" mat-fab extended color="accent"
                  [disabled]="isProcessing"
                  matStepperNext>
            <span *ngIf="!isProcessing">Next</span>
            <mat-spinner *ngIf="isProcessing"></mat-spinner>
          </button>
        </div>
      </mat-step>
      <mat-step [stepControl]="step_02" [formGroup]="step_02">
        <ng-template matStepLabel>Details</ng-template>
        <section class="form-grid">
          <mat-form-field style="grid-column-end: span 2"
                          appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Business Name</mat-label>
            <input matInput formControlName="businessName"
                   (focusin)="showDetails = 'out'" (focusout)="showDetails = 'in'"
                   placeholder="Your Business Name">
          </mat-form-field>


          <mat-form-field appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Company Type</mat-label>
            <mat-select formControlName="companyType"
                        (focusin)="showDetails = 'out'" (focusout)="showDetails = 'in'"
                        placeholder="Your Company Type">
              <mat-option *ngFor="let item of ('COMPANY_TYPE' | dictionaryValues:res.dictionary)" [value]="item">
                {{ item.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Annual Sales</mat-label>
            <mat-select formControlName="annualSales"
                        (focusin)="showDetails = 'out'" (focusout)="showDetails = 'in'"
                        placeholder="Your Annual Sales">
              <mat-option *ngFor="let item of ('ANNUAL_SALES' | dictionaryValues:res.dictionary)" [value]="item">
                {{ item.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Office / Shop Type</mat-label>
            <mat-select formControlName="officeShopType"
                        (focusin)="showDetails = 'out'" (focusout)="showDetails = 'in'"
                        placeholder="Your Office Type">
              <mat-option *ngFor="let item of ('OFFICE_SHOP_TYPE' | dictionaryValues:res.dictionary)" [value]="item">
                {{ item.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Business Age</mat-label>
            <mat-select formControlName="businessAge"
                        (focusin)="showDetails = 'out'" (focusout)="showDetails = 'in'"
                        placeholder="Your Business Type">
              <mat-option *ngFor="let item of ('BUSINESS_AGE' | dictionaryValues:res.dictionary)" [value]="item">
                {{ item.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Office Shop PIN Code</mat-label>
            <input matInput formControlName="officeShopPincode"
                   (focusin)="showDetails = 'out'" (focusout)="showDetails = 'in'"
                   mask="000 000" type="text"
                   placeholder="Enter Pincode">
          </mat-form-field>

          <mat-form-field appearance="outline" color="accent"
                          floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Individual PAN Card</mat-label>
            <input matInput formControlName="individualPanCard"
                   (focusin)="showDetails = 'out'" (focusout)="showDetails = 'in'"
                   mask="SSSSS0000S" type="text"
                   placeholder="Your Pan Card">
          </mat-form-field>
        </section>
        <div style="flex: auto"></div>
        <p style="text-align: center; font-weight: lighter; font-size: small">Rest assured, this will have no impact on
          you credit score!</p>
        <div class="buttons">
          <button mat-button matStepperPrevious>Back</button>
          <button class="next" mat-fab extended color="accent"
                  (click)="send()" [disabled]="isProcessing"
                  matStepperNext>
            <span *ngIf="!isProcessing">Find now</span>
            <mat-spinner *ngIf="isProcessing"></mat-spinner>
          </button>
        </div>
      </mat-step>
      <mat-step [stepControl]="step_02">
        <ng-template matStepLabel>Find out</ng-template>
        <p style="font-weight: normal; font-size: medium">Our system is checking the best offer for you. Our Relationship Manager will contact you shortly.</p>
      </mat-step>

      <ng-template matStepperIcon="edit">
        <mat-icon svgIcon="create"></mat-icon>
      </ng-template>
      <ng-template matStepperIcon="done">
        <mat-icon svgIcon="done"></mat-icon>
      </ng-template>

    </mat-stepper>
  </section>
  <!--
  <pre>{{ form?.getRawValue() | json }}</pre>-->
</section>

<ng-template #loading>
  <p>Loading ...</p>
</ng-template>
