<section *ngIf="offerVariant">
  <section class="page" *ngIf="offer$ | loading:100 | async as result">
    <section class="loading" *ngIf="result.loading">
      <mat-spinner color="accent"></mat-spinner>
    </section>
    <section class="error" *ngIf="result.error">
      <pre>{{(result.error?.error?.failure || result.error) | json}}</pre>
    </section>
    <form class="content" *ngIf="result.value" [formGroup]="form">
      <section class="main">
        <img *ngIf="isShowCashliLogo"
             class="logo" src="assets/cashli_logo.svg" style="cursor: pointer"
             (click)="redirect('https://bit.ly/3sD4zr8')" />
        <h1 class="{{result.value.settings.main.fontClass ?? 'mat-headline-1'}}"
            [innerHTML]="result.value.settings.main.title | safe:'html'"></h1>
        <h4 class="mat-headline-4 subtitle"
            *ngIf="result.value.settings.main.subtitle"
            [innerHTML]="result.value.settings.main.subtitle | safe:'html'"></h4>
        <img class="general" [src]="result.value.settings.main.img" />
        <h4 class="mat-headline-4">{{result.value.settings.main.action}}</h4>
      </section>
      <mat-card class="paytm">
        <div class="logo">
          <img width="91" src="assets/paytm%201.png" />
        </div>
        <mat-card-header align="center">
          <mat-card-title class="mat-headline-2">
            {{result.value.settings.paytm.title}}
          </mat-card-title>
          <mat-card-title class="mat-headline-1">
            {{result.value.settings.paytm.value}}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <section class="form">
            <mat-form-field appearance="outline" color="accent"
                            floatLabel="always" subscriptSizing="dynamic">
              <section style="display: flex; flex-direction: row; font-size: medium">
                <div class="country-code mat-body-2" style="width: 100px">
                  <span style="margin-right: 10px">🇮🇳</span>
                  <span>+91</span>
                </div>
                <input matInput formControlName="phoneNumber" style="font-weight: bolder;"
                       type="text" mask="00 0000 0000"
                       placeholder="Enter Your Paytm number here">
              </section>
            </mat-form-field>
            <button (click)="openBottomSheet()"
                    mat-raised-button
                    color="accent" [disabled]="!form.valid"
                    class="button">
              <mat-icon style="margin: 0" svgIcon="arrow"></mat-icon>
            </button>
          </section>
          <p class="mat-body-1">To receive rewards, ensure your Paytm number has an active wallet</p>
        </mat-card-content>
      </mat-card>
      <section class="ptmInfo">
        <p class="mat-body-1">If you do not have a PayTM account, click on this button to open it. Then you will have to
          go through the <a [href]="ptmLink" target="_blank">link</a> again and fill out the information</p>
        <button mat-raised-button color="accent" [disabled]="false" (click)="redirect(ptmLink)"
                class="button">
          <mat-icon style="margin: 0" svgIcon="arrow"></mat-icon>
        </button>
      </section>
      <section *ngIf="result.value.settings.products">
        <p class="mat-headline-1" style="margin-bottom: 40px">{{result.value.settings.products.title}}</p>
      </section>
      <app-product-card class="product"
                        *ngFor="let product of result.value.settings.products?.items"
                        [product]="product">
      </app-product-card>
      <mat-card class="block"
                style="background-color: {{block.backgroundColor}}; top: -{{ind*32}}px"
                *ngFor="let block of result.value.settings.blocks; let ind = index">
        <mat-card-header>
          <mat-card-title
            [class]="block.title.length > 20 ? 'mat-headline-2' : 'mat-headline-1'">{{block.title}}</mat-card-title>
          <mat-card-title class="mat-headline-6">{{block.subtitle}}</mat-card-title>
        </mat-card-header>
        <mat-card-content [class]="{'withoutBottom': true}">
          <img [src]="block.img" />
        </mat-card-content>
      </mat-card>
      <mat-card class="block terms"
                style="top: -{{result.value.settings.blocks.length*32}}px">
        <mat-chip style="background-color: #FF8080; currentColor: white">IMPORTANT</mat-chip>
        <mat-card-header>
          <mat-card-title class="mat-headline-1">{{result.value.settings.rules.title}}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="mat-body-1"
                          style="text-align: left"
                          [innerHTML]="result.value.settings.rules.text | safe:'html'">
        </mat-card-content>
      </mat-card>
      <section class="footer" style="top: -{{result.value.settings.blocks.length*32}}px">
        <img *ngIf="isShowCashliLogo" width="190" src="assets/cashli_logo.svg" />
        <p class="mat-body-1">
          <a href="http://cashli.in/termsandconditions" target="_blank">Terms and Conditions</a> | <a
          href="http://cashli.in/privacy" target="_blank">Private Policy</a>
        </p>
        <p class="mat-body-1">
          <a href="mailto:support@cashli.in">support@cashli.in</a>
        </p>
      </section>
    </form>
  </section>
</section>
