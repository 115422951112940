import { Injectable } from '@angular/core'
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpHeaderResponse, HttpResponse
} from '@angular/common/http'
import { Observable, tap, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
    .pipe(
      tap(r => {
        if ((r instanceof HttpResponse) && r?.status === 204) {
          window.location.replace("https://cashli.in/deactivatedlink");
          throwError(() => "offer was blocked")
        }
      }),
      catchError((e: HttpErrorResponse) => {
        if (e.status === 403) {
        } else if (e.status === 0) {
          return throwError(() => e.statusText)
        } else {
          return throwError(() => e.error.failure)
        }
        return throwError(() => e)
      })
    );
  }
}
