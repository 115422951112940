<section class="fab">
  <button mat-mini-fab aria-label="close" (click)="close()" [disabled]="isLoading">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</section>
<section class="dialog">
  <section class="header mat-headline-5">Confirm your Paytm number</section>
  <section class="number mat-headline-2">{{data.phoneNumber}}</section>
  <section class="info">
    <p>Ensure the following to receive rewards:</p>
    <ul>
      <li><p>Paytm KYC is completed</p></li>
      <li><p>Maximum Paytm threshold has not been reached</p></li>
    </ul>
  </section>
  <button [disabled]="isLoading" mat-flat-button color="accent" (click)="confirm()">
    <span>{{isLoading ? 'processing...' : 'Got it, please proceed'}}</span>
  </button>
  <button [disabled]="isLoading" (click)="close()" mat-button color="accent">No, input a different number</button>
</section>
